import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { BnNgIdleService } from 'bn-ng-idle';

import { MenuItems } from '../../../shared/menu-items/menu-items';
import { DialogSesionExpiradaComponent } from '../../../sesion-expirada/dialog-sesion-expirada.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: []
})
export class AppSidebarComponent implements OnInit, OnDestroy {

    public config: PerfectScrollbarConfigInterface = {};
    mobileQuery: MediaQueryList;

    private _mobileQueryListener: () => void;
    status = true;

    itemSelect: number[] = [];
    parentIndex: Number;
    childIndex: Number;
    public tienda: string;
    public rol: string;
    setClickedRow(i, j) {
        this.parentIndex = i;
        this.childIndex = j;
    }
    subclickEvent() {
        this.status = true;
    }
    scrollToTop() {
        document.querySelector('.page-wrapper').scroll({
            top: 0,
            left: 0
        });
    }
    permissions: string;

    public userName: String = '';

    constructor(
        private routes: Router,
        public dialog: MatDialog,
        private bnIdle: BnNgIdleService,
        changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher,
        public menuItems: MenuItems
    ) {
        this.mobileQuery = media.matchMedia('(min-width: 768px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
        this.userName = localStorage.getItem('userName');
        this.permissions = localStorage.getItem('permissions');
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }

    ngOnInit() {
        this.tienda = localStorage.getItem('nombreTienda');
        this.rol = localStorage.getItem('rol');
        this.bnIdle.startWatching(36000).subscribe((isTimedOut: boolean) => {
            if (isTimedOut) {
                console.log('session expired' + new Date());
                this.openDialog();
            }
        });
    }

    openDialog() {
        if (this.routes.url !== '/login') {
            const dialogRef = this.dialog.open(DialogSesionExpiradaComponent, {

            });

            dialogRef.afterClosed().subscribe(result => {
                localStorage.clear();
                dialogRef.close();
            });
        }
    }
}
