import { Injectable } from '@angular/core';

export interface BadgeItem {
    type: string;
    value: string;
}
export interface Saperator {
    name: string;
    type?: string;
}
export interface SubChildren {
    state: string;
    name: string;
    type?: string;
}
export interface ChildrenItems {
    state: string;
    name: string;
    type?: string;
    permission?: string;
    child?: SubChildren[];
}

export interface Menu {
    state: string;
    name: string;
    type: string;
    icon: string;
    badge?: BadgeItem[];
    saperator?: Saperator[];
    children?: ChildrenItems[];
}

const MENUITEMS = [
    {
        state: 'dashboard',
        name: 'Dashboard',
        type: 'link',
        icon: 'content_copy',
        permission: 'menu-dashboard'
    },
    {
        state: 'caja',
        name: 'Caja',
        type: 'sub',
        icon: 'local_atm',
        permission: 'menu-caja',
        children: [
            { state: 'abono-caja', name: 'Apertura y abonos', type: 'link', permission: 'submenu-apertura' },
            { state: 'cierre-caja', name: 'Cierre de caja', type: 'link', permission: 'submenu-cierre', }
        ]
    },
    {
        state: 'venta',
        name: 'Ventas',
        type: 'sub',
        icon: 'attach_money',
        permission: 'menu-venta',
        children: [
            { state: 'venta-nueva', name: 'Nueva Venta', type: 'link', permission: 'submenu-venta-nueva-1' },
            { state: 'venta-nueva-2', name: 'Nueva Venta 2.0', type: 'link', permission: 'submenu-venta-nueva-2' },
            { state: 'ventas-recientes', name: 'Ventas Recientes', type: 'link', permission: 'submenu-ventas-recientes' },
            { state: 'libro-ventas', name: 'Libro de Ventas', type: 'link', permission: 'submenu-libro-ventas' },
            { state: 'garantia', name: 'Garantía', type: 'link', permission: 'submenu-garantia' }
        ]
    },
    {
        state: 'empleado',
        name: 'Empleados',
        type: 'link',
        icon: 'person', 
        permission: 'menu-empleado',
    },
    {
        state: 'cliente',
        name: 'Cliente',
        type: 'link',
        icon: 'perm_contact_calendar', 
        permission: 'menu-cliente'
    },
    {
        state: 'company',
        name: 'Convenio Empresas',
        type: 'link',
        icon: 'work', 
        permission: 'menu-convenio'
    },
    {
        state: 'inventario',
        name: 'Inventario',
        type: 'sub',
        icon: 'description', 
        permission: 'menu-inventario',
        children: [
            { state: 'maestro', name: 'Data Maestra', type: 'link', permission: 'submenu-maestro' },
            { state: 'marco', name: 'Marcos', type: 'link', permission: 'submenu-marco' },
            { state: 'accesorio', name: 'Accesorios', type: 'link', permission: 'submenu-accesorio' },
            { state: 'merma', name: 'Merma', type: 'link', permission: 'submenu-merma', }
        ]
    },
    {
        state: 'insumos',
        name: 'Insumos',
        type: 'link',
        icon: 'assignment', 
        permission: 'menu-insumo'
    },
    {
        state: 'reportes',
        name: 'Reportes',
        type: 'sub',
        icon: 'insert_drive_file', 
        permission: 'menu-reporte',
        children: [
            { state: 'reportes-productos', name: 'Productos', type: 'link', permission: 'submenu-reporte-producto' },
            { state: 'reportes-venta', name: 'Venta', type: 'link', permission: 'submenu-reporte-venta' },
            { state: 'reportes-insumos', name: 'Insumos', type: 'link', permission: 'submenu-reporte-insumo' }
        ]
    },
    {
        state: 'tiendas',
        name: 'Tiendas',
        type: 'link',
        icon: 'store', 
        permission: 'menu-tienda'
    }
];

const MENUITEMSVENDEDOR = [

    {
        state: 'caja',
        name: 'Caja',
        type: 'sub',
        icon: 'local_atm', permission: '',
        children: [
            { state: 'abono-caja', name: 'Apertura y abonos', type: 'link', permission: '', },
            { state: 'cierre-caja', name: 'Cierre de caja', type: 'link', permission: '', }
        ]
    },
    {
        state: 'venta',
        name: 'Ventas',
        type: 'sub',
        icon: 'attach_money', permission: '',
        children: [
            { state: 'venta-nueva', name: 'Nueva Venta', type: 'link', permission: '', },
            { state: 'ventas-recientes', name: 'Ventas Recientes', type: 'link', permission: '', },
            { state: 'libro-ventas', name: 'Libro de Ventas', type: 'link', permission: '', },
            { state: 'garantia', name: 'Garantía', type: 'link', permission: '', }
        ]
    },
    {
        state: 'insumos',
        name: 'Insumos',
        type: 'link',
        icon: 'assignment', permission: '',
    },
    {
        state: 'reportes',
        name: 'Reportes',
        type: 'sub',
        icon: 'insert_drive_file', permission: '',
        children: [
            { state: 'reportes-productos', name: 'Productos', type: 'link', permission: '', }
        ]
    },
];

const MENUITEMSVENDEDORREPO = [

    {
        state: 'caja',
        name: 'Caja',
        type: 'sub',
        icon: 'local_atm', permission: '',
        children: [
            { state: 'abono-caja', name: 'Apertura y abonos', type: 'link', permission: '', },
            { state: 'cierre-caja', name: 'Cierre de caja', type: 'link', permission: '', }
        ]
    },
    {
        state: 'venta',
        name: 'Ventas',
        type: 'sub',
        icon: 'attach_money', permission: '',
        children: [
            { state: 'venta-nueva', name: 'Nueva Venta', type: 'link', permission: '', },
            { state: 'ventas-recientes', name: 'Ventas Recientes', type: 'link', permission: '', },
            { state: 'libro-ventas', name: 'Libro de Ventas', type: 'link', permission: '', },
            { state: 'garantia', name: 'Garantía', type: 'link', permission: '', }
        ]
    },
    {
        state: 'insumos',
        name: 'Insumos',
        type: 'link',
        icon: 'assignment', permission: '',
    },
    {
        state: 'reportes',
        name: 'Reportes',
        type: 'sub',
        icon: 'insert_drive_file', permission: '',
        children: [
            { state: 'reportes-productos', name: 'Productos', type: 'link', permission: '', },
            { state: 'reportes-venta', name: 'Venta', type: 'link', permission: '', },
            { state: 'reportes-insumos', name: 'Insumos', type: 'link', permission: '', }
        ]
    },
];

const MENUITEMSBODEGA = [
    {
        state: 'inventario',
        name: 'Inventario',
        type: 'sub',
        icon: 'description', permission: '',
        children: [
            { state: 'marco', name: 'Marcos', type: 'link', permission: '', },
            { state: 'accesorio', name: 'Accesorios', type: 'link', permission: '', },
            { state: 'merma', name: 'Merma', type: 'link', permission: '', }
        ]
    },
    {
        state: 'reportes',
        name: 'Reportes',
        type: 'sub',
        icon: 'insert_drive_file', permission: '',
        tienda: 'San Pablo',
        children: [
            { state: 'reportes-bodega', name: 'Productos', type: 'link', permission: '', },
        ]
    },
];

@Injectable()
export class MenuItems {
    getMenuitem(): Menu[] {
        // let rol;
        // switch (localStorage.getItem('rol')) {
        //     case 'ADMINISTRADOR':
        //         rol = MENUITEMS;
        //         break;

        //     case 'VENDEDOR':
        //         rol = MENUITEMSVENDEDOR;
        //         break;
            
        //     case 'VENDEDOR_REPO':
        //         rol = MENUITEMSVENDEDORREPO;
        //         break;

        //     case 'BODEGA':
        //         rol = MENUITEMSBODEGA;
        //         break;
        // }

        // return rol;
        return MENUITEMS;

    }
}
