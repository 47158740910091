export class Usuario {

    _id: string;
    nombre: string;
    rut: string;
    email: string;
    telefono: string;
    password: string;
    tipo:string;
    token:string;
    lastLogin:Date;
    estado:string;
    rol:string;
    comisionPagada:string;
    comisionPendiente:string;
    comisionTotal:string;
    porcentajeComision:string;
    tienda:string;
    nombreCaptador:string;
    nombreTienda:string;
    permissions: string;
}