export abstract class Constantes {

    //variables localstorage
    public static TOKEN: string = "token";
    public static TIENDA: string = "tienda";
    public static NOMBRE_TIENDA: string = "nombreTienda";
    public static ROL: string = "rol";
    public static NOMBRE: string = "userName";
    public static ID: string = "userId";
    public static RUT: string = "rut";
    public static EMAIL: string = "email";

    //constantes diagolos
    public static AGREGAR: string = "Agregar";
    public static ACTUALIZAR: string = "Actualizar";
    public static ELIMINAR: string = "Eliminar";
    public static ABONAR_A_CAJA: string = "Abonar a la caja";
    public static RETIRAR_A_CAJA: string = "Retirar de la caja";
    public static ABRIR_CAJA: string = "Abrir caja";
    public static PAGAR_COMISION: string = "Pagar Comisión";
    public static MERMA: string = "Merma";
    public static IMPORTAR: string = "Importar";

    // constantes error enduro
    public static ERROR_PAGO_EXCEDE_COMISION = "El monto a pagar no puede ser mayor al de la comisión";
    public static ERROR_DIALOG_ACTION = "No se pudo realizar la acción";
    public static ERROR_LOGIN = "Usuario o contraseña inválido";

    //constantes info enduro
    public static INFO_CANCELA_ACCION_DIALOG = "Cancela acción";

    //constantes fecha
    public static DATE_FORMAT_YYYY_MM_DD = "yyyy-MM-dd";
    public static DATE_LANGUAGE_EN_US = "en-US";

    // constantes puntuacion
    public static PUNTUACION_MASK = "###.###.###";

    STUDENT_NAMES: ["JOHN", "BOB", "NICK"];

    //constantes campos grillas
    public static GRILLA_CAPTADORES: string[] = [
        'nombre',
        'rut',
        'email',
        'comisionPagada',
        'comisionPendiente',
        'comisionTotal',
        'porcentajeComision',
        'action'
    ];
    public static GRILLA_EMPLEADOS: string[] = ['nombre', 'rut', 'email', 'comisionTotal', 'action'];

    public static GRILLA_ABONO_CAJA: string[] = ['tipo', 'description', 'monto', 'hora', 'usuarioNombre'];

    public static GRILLA_CIERRE_CAJA_ABONOS: string[] = [
        'numeroBoleta',
        'formaPago',
        'monto',
        'action'
    ];

    public static GRILLA_CIERRE_CAJA_INSUMOS: string[] = [
        'nombre',
        'detalle',
        'formaPago',
        'precio'
    ];

    public static GRILLA_CIERRE_CAJA_COMISION: string[] = [
        'numeroBoleta',
        'nombreCaptador',
        'montoComision'
    ];

    public static GRILLA_CIERRE_CAJA_COMISION_PAGADA: string[] = [
        'numeroBoleta',
        'estadoVenta',
        'nombreCaptador',
        'comisionPagada'
    ];

    public static GRILLA_INSUMOS: string[] = [
        'nombre',
        'detalle',
        'precio',
        'proveedor',
        'numeroFactura',
        'formaPago',
        'fechaCreacion',
        'usuario',
        'action'
    ];

    public static GRILLA_MAESTRO: string[] = [
        'tipo_producto',
        'producto',
        'sku',
        'codigo_barras'
    ];

    public static GRILLA_MAESTRO_ASISTIDO: string[] = [
        'producto',
        'codigo_barras',
        'stock'
    ];

    public static GRILLA_CLIENTE: string[] = [
        'nombres',
        'rut',
        'phone',
        'fonoFijo',
        'fechaCreacion',
        'action'
    ];

    public static GRILLA_COMPANY: string[] = [
        'name',
        'documentNumber',
        'representative',
        'contact',
        'agreementDate',
        'actions'
    ];

    public static GRILLA_MARCO: string[] = [
        'nombre',
        'codigo',
        'color',
        'precio',
        'stock',
        'vendidos',
        'action'
    ];

    public static GRILLA_MARCO_VENTA: string[] = [
        'nombre',
        'codigo',
        'color',
        'precio',
        'stock',
        'action'
    ];

    public static GRILLA_ACCESORIO: string[] = [
        'nombre',
        'codigo',
        'color',
        'precio',
        'stock',
        'vendidos',
        'action'
    ];

    public static GRILLA_MERMA: string[] = [
        'nombre',
        'codigo',
        'color',
        'precio',
        'stock'
    ];

    public static ROLES: string[] = [
        'Vendedor',
        'Administrador'
    ];

    public static GRILLA_VENTA_FINALIZADA_ABONO: string[] = [
        'abono',
        'formaPago',
        'fechaAbono'
    ];

    public static GRILLA_VENTA_ANULADA_ABONO: string[] = [
        'abono',
        'formaPago',
        'fechaAbono'
    ];

    public static GRILLA_GARANTIA_GENERAL: string[] = [
        'numeroBoleta',
        'nombre',
        'fechaVenta',
        'action'
    ];

    public static GRILLA_VENTA_ANULADA_GENERAL: string[] = [
        'numeroBoleta',
        'nombre',
        'montoTotal',
        'fechaVenta',
        'fechaAnulada',
        'action'
    ];

    public static GRILLA_VENTA_PENDIENTE_GENERAL: string[] = [
        'numeroBoleta',
        'nombre',
        'vendedor',
        'montoTotal',
        'montoCancelado',
        'montoPendiente',
        'fechaVenta',
        // 'fechaActualizacion',
        'estado',
        'action'
    ];

    public static GRILLA_VENTA_PENDIENTE_ABONOS: string[] = [
        'abono',
        'formaPago',
        'fechaAbono',
        'action'
    ];

    public static GRILLA_VENTA_FINALIZADA_GENERAL: string[] = [
        'numeroBoleta',
        'nombre',
        'montoTotal',
        'fechaVenta',
        'fechaCierre',
        'action'
    ];

    //redireciones
    public static REDIRECCIONAR_VENTA_NUEVA: string = 'venta/venta-nueva';

    //valores paginacion
    public static ITEMS_POR_PAGINA: string = "Items por página";
    public static PRIMERA_PAGINA = "Primera página";
    public static ULTIMA_PAGINA = "Última página";
    public static PAGINA_SIGUIENTE = "Página siguiente";
    public static PAGINA_ANTERIOR = "Página anterior";

    //tipos graficos dashboard
    public static GRAFICO_PIE: string = "pie";
    public static GRAFICO_LINE: string = "line";
    public static GRAFICO_DONA: string = "doughnut";
}